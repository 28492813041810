import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Hr from '../components/Hr'
import Column from '../components/Column'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'
import Subtitle from '../components/SubTitle'
import Div from './../components/Div'
import ImgDDOSPremium from '../images/ddos-remium.svg'
import ImgProtecaoGlobal from '../images/protecao-global.svg'

import { Container } from '../components/styles/Layout.styles'
import Button from '../components/Button/Button'

import { 
  styledProtectionDdos,
  header,
  content,
  protectionDdosItems,
  boxHeader,
  boxContent
} from '../components/styles/ProtectionDDOS.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledProtectionDdos}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >            
            <H4>
              <span>Proteção ddos</span>
              Proteção e Segurança DDoS HostDime
            </H4>
            <Paragraph>Seja cliente HostDime e tenha o seu site protegido por uma das melhores soluções contra ataques DDoS do mundo.</Paragraph>
            <Paragraph>Um ataque DDoS em um cliente pode colocar a segurança da sua empresa em risco. Felizmente, a HostDime oferece para todos os clientes alocados em nosso Data Center a Proteção DDoS Premium via hardware, baseada na monitorização e mitigação desses tipos de ataque. O serviço é fruto de uma parceria com a Arbor Networks, empresa líder de mercado em segurança de rede.</Paragraph>
            <Paragraph>Através do sistema Arbor PeakFlow SP é possível proteger a rede tanto contra ataques volumétricos quanto a ataques em camada de aplicação. Com isso, você pode contar com a melhor proteção para seus serviços críticos de e-mail, httpd, DNS e aplicações de vídeo, voz e e-commerce.</Paragraph>
            <Paragraph>Desfrute da tranquilidade em relação às ameaças de segurança de rede e mantenha seu foco em outro projetos essenciais para o seu negócio. Ao contar com o serviço de Proteção DDoS Premium da HostDime você terá um uptime superior, acesso ininterrupto ao Data Center e aumento da retenção de clientes.</Paragraph>
          </Column>
        </Row>
        <Row
          flexDirection={['column']}
          justifyContent={'center'}
          css={content}
          mt={[14, 14, 34, 34]}
        >
          <Column
            width={[1]}
          >  
            <H5 style={{ textAlign: 'left' }}>Conheça nossos dois serviços exclusivos de proteção e segurança contra ataques DDoS</H5>
          </Column>
        </Row>
        <Row
          flexDirection={['column', 'column', 'column', 'row']}
          css={protectionDdosItems}
          mb={50}
        >
          <Column>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div css={boxHeader}>Tipos de filtragem?</div>
              <div css={boxContent}>
                <div>
                  <Subtitle>DDoS<br />Premium </Subtitle>
                  <span>Filtragens em linha</span>
                </div>
                <div>
                  <Subtitle>DDoS<br />Cloud</Subtitle>
                  <span>Filtragem GRE</span>
                </div>
              </div>
            </div>
          </Column>
          <Column
            mt={[50, 50, '0px', '0px']}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div css={boxHeader}>Latência Adicional?</div>
              <div css={boxContent}>
                <div>
                  <Subtitle>DDoS<br />Premium </Subtitle>
                  <span>Sem latência adicional</span>
                </div>
                <div>
                  <Subtitle>DDoS<br />Cloud</Subtitle>
                  <span>Com latência adicional minizada</span>
                </div>
              </div>
            </div>
          </Column>
        </Row>
        <Row
          flexDirection={['column', 'column', 'row', 'row']}
          css={protectionDdosItems}
          mb={50}
        >
          <Column>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div css={boxHeader}>Monitoramento Constante?</div>
              <div css={boxContent}>
                <div>
                  <Subtitle>DDoS<br />Premium </Subtitle>
                  <span>Sim</span>
                </div>
                <div>
                  <Subtitle>DDoS<br />Cloud</Subtitle>
                  <span>Sim</span>
                </div>
              </div>
            </div>
          </Column>
          <Column
            mt={[50, 50, '0px', '0px']}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div css={boxHeader}>Tipos de proteção?</div>
              <div css={boxContent}>
                <div>
                  <Subtitle>DDoS<br />Premium </Subtitle>
                  <span>Proteção sob demandal</span>
                </div>
                <div>
                  <Subtitle>DDoS<br />Cloud</Subtitle>
                  <span>Proteção constante</span>
                </div>
              </div>
            </div>
          </Column>
        </Row>
        <Row
          flexDirection={['column', 'column', 'row', 'row']}
          css={protectionDdosItems}
          mb={50}
        >
          <Column>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div css={boxHeader} style={{ padding: '9px 0' }}>Capacidade máxima de <br />filtragens destes ataques?</div>
              <div css={boxContent}>
                <div>
                  <Subtitle>DDoS<br />Premium</Subtitle>
                  <span>10Gbps</span>
                </div>
                <div>
                  <Subtitle>DDoS<br />Cloud</Subtitle>
                  <span>100Gbps</span>
                </div>
              </div>
            </div>
          </Column>
          <Column
            mt={[50, 50, '0px', '0px']}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div css={boxHeader}>Redes necessárias?</div>
              <div css={boxContent}>
                <div>
                  <Subtitle>DDoS<br />Premium</Subtitle>
                  <span>Qualquer IP pode ser protegido</span>
                </div>
                <div>
                  <Subtitle>DDoS<br />Cloud</Subtitle>
                  <span>Será necessário IPs especiais</span>
                </div>
              </div>
            </div>
          </Column>
        </Row>
        <Row
          flexDirection={['column', 'column', 'row', 'row']}
          css={protectionDdosItems}
          mb={50}
        >
          <Column>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div css={boxHeader}>Como é controlado?</div>
              <div css={boxContent}>
                <div>
                  <Subtitle>DDoS<br />Premium </Subtitle>
                  <span>Controlado exclusivamente via Chamado através do painel Core</span>
                </div>
                <div>
                  <Subtitle>DDoS<br />Cloud</Subtitle>
                  <span>Controlado via chamado ou<br /> solicitado pelo cliente via BGP.</span>
                </div>
              </div>
            </div>
          </Column>
          <Column
            mt={[50, 50, '0px', '0px']}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div css={boxHeader}>Configuração adicional de rede?</div>
              <div css={boxContent}>
                <div>
                  <Subtitle>DDoS<br />Premium</Subtitle>
                  <span>Não</span>
                </div>
                <div>
                  <Subtitle>DDoS<br />Cloud</Subtitle>
                  <span>Sim, alterações de IP seriam necessárias</span>
                </div>
              </div>
            </div>
          </Column>
        </Row>
        <Row
          flexDirection={['column', 'column', 'row', 'row']}
          css={protectionDdosItems}
          mb={50}
        >
          <Column>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div css={boxHeader}>Custo?</div>
              <div css={boxContent}>
                <div>
                  <Subtitle>DDoS<br />Premium</Subtitle>
                  <span>A partir de $75.00 / mês por Servidor</span>
                </div>
                <div>
                  <Subtitle>DDoS<br />Cloud</Subtitle>
                  <span>$100.00/mensais <br />por / 29 (ou 5 IPs)</span>
                </div>
              </div>
            </div>
          </Column>
          <Column
            mt={[50, 50, '0px', '0px']}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div css={boxHeader} style={{ padding: '9px 0' }}>Quantidade de tráfego legítimo <br />a ser obtido?</div>
              <div css={boxContent}>
                <div>
                  <Subtitle>DDoS<br />Premium</Subtitle>
                  <span>Tráfego limpo ilimitado</span>
                </div>
                <div>
                  <Subtitle>DDoS<br />Cloud</Subtitle>
                  <span>Limpe seu tráfego baseado <br />em banda larga.</span>
                </div>
              </div>
            </div>
          </Column>
        </Row>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={[328, '100%', 1032, 1032]}
          display={['none', 'none', 'flex', 'flex']}
          style={{
            opacity: 0.5,
            margin: '0 auto'
          }}
        />
        <Row
          flexDirection={['column']}
          justifyContent={'center'}
          css={content}
          mt={[40]}
          mb={[50]}
        >
          <Column
            width={[1]}
          >  
            <H5>Segurança DDoS<br /> Premium da HostDime Brasil</H5>
            <Div
              maxWidth={['100%', '100%', 120, 130]}
              style={{
                margin: '0 auto'
              }}
            >
              <img
                src={ImgDDOSPremium} 
                style={{
                  width: 130
                }}
              />
            </Div>
            <Paragraph>Um ataque de grande proporção pode saturar a capacidade de rede do seu servidor, o deixando indisponível por causa do volume de tráfego malicioso recebido, não deixando espaço para que o tráfego legítimo flua.</Paragraph>
            <Button
              outlined
              schema='lightPeriwinkle'
              align='center'
              mb={[3]}
              style={{
                color: '#737474',
                fontWeight: 600,
                letterSpacing: 'normal',
                lineHeight: 1.43,
                padding: '12px 16px 12px',
                width: 152,
                textTransform: 'capitalize',
                border: 'solid 1px rgba(0, 0, 0, 0.12)',
                outline: 'none',
                display: 'flex',
                margin: '0 auto',
                justifyContent: 'center'
              }}
            >
              SAIBA MAIS
            </Button>
          </Column>
        </Row>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={[328, '100%', 1032, 1032]}
          style={{
            opacity: 0.5,
            margin: '0 auto'
          }}
        />
        <Row
          flexDirection={['column']}
          justifyContent={'center'}
          css={content}
          mt={[40]}
          mb={[50]}
        >
          <Column
            width={[1]}
          >  
            <H5>Proteção e Segurança <br />Global Cloud Hostdime</H5>
            <Div
              maxWidth={['100%', '100%', 140, 130]}
              style={{
                margin: '0 auto'
              }}
            >
              <img
                src={ImgProtecaoGlobal} 
                style={{
                  width: 140
                }}
              />
            </Div>
            <Paragraph>A Proteção DDoS Cloud da HostDime funciona como um serviço de roteamento de IPs. Todos os tráfegos serão analisados e filtrados, então o tráfego limpo é encaminhado para a nossa rede Premium através dos nossos quatro túneis de encapsulamento Point-to-Point e roteamento (GRE). Essa “nuvem de segurança” utiliza um link privado entre nodes de rede e atua como uma rede de segurança contra ataques DDoS de grandes proporções. Com essa proteção, sua infraestrutura permanecerá protegida, incluindo seus sites, e-mails e FTP.</Paragraph>
            <Button
              outlined
              schema='lightPeriwinkle'
              align='center'
              mb={[3]}
              style={{
                color: '#737474',
                fontWeight: 600,
                letterSpacing: 'normal',
                lineHeight: 1.43,
                padding: '12px 16px 12px',
                width: 170,
                textTransform: 'capitalize',
                border: 'solid 1px rgba(0, 0, 0, 0.12)',
                outline: 'none',
                display: 'flex',
                margin: '0 auto',
                justifyContent: 'center'
              }}
            >
              SAIBA MAIS
            </Button>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Proteção DDOS' />
      <PageTemplate
        title='Proteção DDOS'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ddos/ddos-premium.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
