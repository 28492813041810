import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledProtectionDdosBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [14, 14, 34, 34]
  })
)

export const styledProtectionDdos = css`
  ${styledProtectionDdosBps};
`

export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [8],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left'],
    fontSize: [33.6, 33.6, 33.6, 47.9]
  })
)

export const H5HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 504, 504],
    textAlign: ['center'],
    marginBottom: [40]
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['left']
  })
)

export const PContentBps = css(
  mq({
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left']
  })
)

export const contentBps = css(
  mq({
    marginLeft: [0, 0, 288, 390],
    marginRight: [0, 0, 288, 390],
  })
)

export const protectionItemsBps = css(
  mq({
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 288, 302],
  })
)

export const boxHeaderBps = css(
  mq({
    width: [328, '100%', 328, 328]
  })
)

export const lineBorderBps = css(
  mq({
    maxWidth: [328, 422, 680, 603],
    marginLeft: [0, 89, 200, 340],
    marginRight: [0, 89, 200, 340]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    display: grid;
  }
  h5 {
    ${H5HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
    margin-bottom: 16px;
  }
  img {
    max-width: 328px;
  }
`

export const content = css`
  ${contentBps};
  display: flex;
  h5 {
    ${H5HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PContentBps};
    margin-bottom: 30px;
  }
  img {
    margin-bottom: 40px;
  }
`

export const lineBorder = css`
  ${lineBorderBps};
  margin: 0 auto;
  height: 1px;
  border-bottom: solid 1px rgba(217, 222, 227, 0.5);
  margin-top: 49.5px;
  margin-bottom: 40.5px;
`

export const protectionDdosItems = css`
  ${protectionItemsBps};
`

export const boxHeader = css`
  ${boxHeaderBps};
  border-radius: 28px;
  background-color: #7f22fd;
  color: #f9fafa;
  font-size: 15.7px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.53;
  letter-spacing: 0.15px;
  text-align: center;
  padding: 16px 0;
  margin-bottom: 16px;
`

export const boxContent = css`
  display: flex;
  flex-direction: column;
  p {
    color: rgba(0, 0, 0, 0.87);
  }
  span {
    margin-left: auto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: right;
    color: rgba(0, 0, 0, 0.87);
  }
  div:nth-last-child(2) {
    display: flex;
    align-items: center;
    padding-bottom: 15.5px;
  }
  div:nth-last-child(1) {
    border-top: 1px solid #d9dee3;
    display: flex;
    align-items: center;
    padding-top: 15.5px;
  }
`